
import React, { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import {SzChart} from "@suezenv/react-theme-components";
import {formatServerDate} from "../../../main/utils";
import "./style.scss";

const ProgramminFlowChart: any = (props: any) => {
    const {t} = useTranslation();

    const [flowData, setFlowData] = useState([]);
    const {step, startAt, endAt, measureDetailDayByStep, setUnitConversion, unitConversion} = props;
    
    useEffect(() => {
        const computedFlow = props.measureDetailDayByStep && props.measureDetailDayByStep.flow ? props.measureDetailDayByStep.flow : [];
        setFlowData(computedFlow);

        // set unit according to the flow max value
        let unit = {unit: "L", conversion: 1};
        computedFlow.forEach((item: any) => {
            if(item.total >= 1000) {
                unit = {unit: "m3", conversion: 1000};
            }
        });
        setUnitConversion(unit);

    }, [measureDetailDayByStep])

    const getWateringCategories = () => {
        const categories = [];
        const startDate: Date = new Date(startAt);
        const endDate: Date = new Date(endAt);
        for (const d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
            categories.push(formatServerDate(d));
        }
        return categories;
    };
    const initCategories = () => {
        const categories: any = [];
        const startDate: Date = new Date(startAt);
        const endDate: Date = new Date(endAt);
        for (const d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
            categories[formatServerDate(d)] = null;
        }
        return categories;
    };

    const getFlowData = () => {
        const categories: any = initCategories();

        flowData.forEach((item: any) => {
            const index = formatServerDate(item._id.date);
            categories[index] = (item.total !== null) ? parseFloat((item.total / unitConversion.conversion).toFixed(1)) : computeMaxValueOfNull();
        });
        return Object.values(categories);
    };

    const getSensorData = () => {
      const categories: any = initCategories();
      const sensor = (props.measureDetailDayByStep &&
          props.measureDetailDayByStep.sensor)
          ? props.measureDetailDayByStep.sensor: [];
      sensor.forEach((item: any) => {
        const index = formatServerDate(item._id.date);
        categories[index] = item.total ? parseFloat(item.total.toFixed(1)) : null;
      });
      return Object.values(categories);
    };

    /**
     * On the graph, it is not possible to display a bar with a zero value. 
     * To circumvent this restriction, a default value must be defined. 
     * This value should be slightly higher than the maximum value of the flow. 
     * This way, it is possible to assign it a gray color.
     */
    const computeMaxValueOfNull = () =>  {
        if(flowData.length) {
            // retrieve the max value of the flow array
            const flowMax: number = Math.max(...flowData.map((item: any) => item.total / unitConversion.conversion));

            // add a margin of 0.1 to differenciate from the max value of the flow
            return flowMax + 0.1;
        }
        return 0;
    }

    const getFlowUnit = () =>  {
        return unitConversion.unit;
    }

    const getChartOptions = () => {
        const yAxis:any[] = [{
            min: 0,
            title: {
                text: "Arrosage",
                useHTML: true,
                style: {
                    color: "#84DFFF",
                }
            },
            labels: {
                format: "{value} "+getFlowUnit(),
                useHTML: true,
                style: {
                    color: "#84DFFF",
                }
            },
        }];
        const series: any[] = [
          {
            name: "",
            data: getFlowData(),
            pointWidth: 15,
            pointPadding: 0,
            yAxis: 0,
            zones: [
              {
                value: computeMaxValueOfNull(),
                color: "#7cb5ec", // bar blue color for valid values
              },
              {
                color: "#e0e0e0", // bar grey color for null values
              },
            ],
            tooltip: {
              pointFormatter: function (): any {
                let point: any = this;
                return point.y == computeMaxValueOfNull() ? "NA" : `${point.y} (${getFlowUnit()})`;
              },
            },
          },
        ];
        if (props.circuitHasHumidity)
        {
            yAxis.push({
                min: 0,
                title: {
                    text: "Humidité",
                    useHTML: true,
                    style: {
                        color: "red",
                    }
                },
                labels: {
                    format: "{value} %",
                    useHTML: true,
                    style: {
                        color: "red",
                    }
                },
                opposite: true
            });

            series.push({
                name: "",
                data: getSensorData(),
                pointWidth: 2,
                color: "red",
                pointPadding: 0,
                type: "line",
                marker: {
                    enabled: false,
                },
                yAxis: 1
            });
        }
        return {
            title: {
                align: "left",
                useHTML: true,
                text: "",
            },
            chart: {
                type: "column",
            },
            rangeSelector: {
                selected: 2,
            },
            xAxis: {
                title: {
                    useHTML: true,
                    text: "",
                },
                labels: {
                    useHTML: true,
                    format: "<h6 class='chart-text'>{value}</h6>",
                    style: {
                        color: "",
                    },
                },
                categories:
                    getWateringCategories(),

                crosshair: true,
            },
            yAxis: yAxis,
            exporting: false,
            plotOptions: {
                column: {
                    pointPadding: 0,
                    borderWidth: 0,
                    colors: ["#84DFFF"],
                },
            },
            legend: {
                enabled: false,
            },
            series: series
        };
    };

    return (
        <SzChart options={getChartOptions()}/>);
};

export default ProgramminFlowChart;
