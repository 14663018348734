import moment from "moment";
import React, { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import {SzChart} from "@suezenv/react-theme-components";
import "./style.scss";
import {
    HUNTER_SOURCE,
    HUNTER_STEP_MEASURE,
    SOLEM_STEP_MEASURE
} from "../../../main/store/constants";

const ProgrammingChart: any = (props: any) => {

    const [flowCategory, setCategory] = useState([]);
    const [flowData, setFlowData] = useState([]);

    const numberHourPerDay = 24;
    const numberMinutePerHour= 60;
    const {circuit, measureDetailDayByStep, setUnitConversion, unitConversion} = props;
    const source :string = circuit && circuit.source;

    const measureStep = source === HUNTER_SOURCE ? HUNTER_STEP_MEASURE : SOLEM_STEP_MEASURE;

    useEffect(() => {

        // get flow values for category
        let computedFlow = measureDetailDayByStep && measureDetailDayByStep.flow ? measureDetailDayByStep.flow : [];
        setCategory(computedFlow);

        // get flow values for data
        const maxNumberOfSteps = (numberMinutePerHour / measureStep) * numberHourPerDay;
        computedFlow = (measureDetailDayByStep && measureDetailDayByStep.flow) && (measureDetailDayByStep.flow.length > maxNumberOfSteps) ? measureDetailDayByStep.flow.slice(0, maxNumberOfSteps) : measureDetailDayByStep.flow;
        setFlowData(computedFlow);
        
        // set unit according to the flow max value
        let unit = {unit: "L", conversion: 1};
        computedFlow.forEach((item: any) => {
            if(item.total >= 1000) {
                unit = {unit: "m3", conversion: 1000};
            }
        });
        setUnitConversion(unit);

    }, [measureDetailDayByStep])

    const getWateringCategories = () => {
        const categories = [];
        if (source === HUNTER_SOURCE && flowCategory.length > 0) {
            flowCategory.forEach((item: any) => {
                // use utc to keep the same hour of server date
                const index = moment(item._id.date).local().format("HH:mm");
                categories.push(index);
            });
        }else
        {
            for (let hourValue = 0; hourValue < numberHourPerDay; hourValue++) {

                for (let minuteValue = 0; minuteValue < numberMinutePerHour; minuteValue = minuteValue + measureStep) {

                    const hour = (hourValue < 10) ? `0${hourValue}` : hourValue;
                    const minute = (minuteValue < 10) ? `0${minuteValue}` : minuteValue;
                    categories.push(`${hour}:${minute}`);
                }
            }
        }
        return categories;
    };
    const initCategories = () => {
        const categories: any = [];
        for (let hourValue = 0; hourValue < 24; hourValue++) {

            for (let minuteValue = 0; minuteValue < 60; minuteValue = minuteValue + measureStep) {
                const hour = (hourValue < 10) ? `0${hourValue}` : hourValue;
                const minute = (minuteValue < 10) ? `0${minuteValue}` : minuteValue;
                categories[`${hour}:${minute}`] = null;
            }
        }

        return categories;
    };

    const getFlowData = () => {
        const categories = (source === HUNTER_SOURCE && flowData.length > 0) ? [] : initCategories();
        flowData.forEach((item: any) => {
            // use utc to keep the same hour of server date
            const index = moment(item._id.date).local().format("HH:mm");
            categories[index] = (item.total !== null) ? parseFloat((item.total / unitConversion.conversion).toFixed(1)) : computeMaxValueOfNull();
        });
        return Object.values(getOrderedList(categories));
    };

    const getOrderedList = (unordered: any) => Object.keys(unordered).sort().reduce(
        (obj: any, key: any) => {
            obj[key] = unordered[key];
            return obj;
        },
        {}
    );

    const getSensorData = () => {

        const categories: any = initCategories();
        // const sensor = (props.measureDetailDayByStep && props.measureDetailDayByStep.sensor && props.measureDetailDayByStep.sensor.length) > numberHourPerDay ? props.measureDetailDayByStep.sensor.slice(0, numberHourPerDay) : props.measureDetailDayByStep.sensor ;
           const sensor =
             props.measureDetailDayByStep && props.measureDetailDayByStep.sensor
               ? props.measureDetailDayByStep.sensor
               : [];
        sensor.forEach((item: any) => {
            for (let j = 0; j < numberMinutePerHour; j = j + SOLEM_STEP_MEASURE) {
                const minute = (j < 10) ? `0${j}` : j;

                // use utc to keep the same hour of server date
                const hour = moment(item._id.date).local().format("HH");
                const index = `${hour}:${minute}`;
                categories[index] = item.total ? parseFloat((item.total).toFixed(1)) : null;
            }
        });

        return Object.values(getOrderedList(categories));
    };

    /**
     * On the graph, it is not possible to display a bar with a zero value. 
     * To circumvent this restriction, a default value must be defined. 
     * This value should be slightly higher than the maximum value of the flow. 
     * This way, it is possible to assign it a gray color.
     */
    const computeMaxValueOfNull = () =>  {
        if(flowData.length) {
            // retrieve the max value of the flow array
            const flowMax: number = Math.max(...flowData.map((item: any) => item.total / unitConversion.conversion));

            // add a margin of 0.1 to differenciate from the max value of the flow
            return flowMax + 0.1;
        }
        return 0;
    }

    const getFlowUnit = () =>  {
        return unitConversion.unit;
    }

    const getChartOptions = () => {
        const yAxis:any[] = [{
            min: 0,
            title: {
                text: "Arrosage",
                useHTML: true,
                style: {
                    color: "#84DFFF",
                }
            },
            labels: {
                format: "{value} "+getFlowUnit(),
                useHTML: true,
                style: {
                    color: "#84DFFF",
                }
            },
        }];
        const series: any[] = [
          {
            name: "",
            data: getFlowData(),
            pointWidth: 15,
            pointPadding: 0,
            yAxis: 0,
            zones: [
              {
                value: computeMaxValueOfNull(),
                color: "#7cb5ec", //  blue color for valid values
              },
              {
                color: "#e0e0e0", // grey color for null values
              },
            ],
            tooltip: {
              pointFormatter: function (): any {
                let point: any = this;
                return point.y == computeMaxValueOfNull() ? "NA" : `${point.y} (${getFlowUnit()})`;
              },
            },
          },
        ];
        if (props.circuitHasHumidity)
        {
            yAxis.push({
                min: 0,
                title: {
                    text: "Humidité",
                    useHTML: true,
                    style: {
                        color: "red",
                    }
                },
                labels: {
                    format: "{value} %",
                    useHTML: true,
                    style: {
                        color: "red",
                    }
                },
                opposite: true
            });

            series.push({
                name: "",
                data: getSensorData(),
                pointWidth: 2,
                color: "red",
                pointPadding: 0,
                type: "line",
                marker: {
                    enabled: false,
                },
                yAxis: 1
            });
        }
        return {
            title: {
                align: "left",
                useHTML: true,
                text: "",
            },
            chart: {
                type: "column",
            },
            rangeSelector: {
                selected: 2,
            },
            xAxis: {
                title: {
                    useHTML: true,
                    text: "",
                },
                labels: {
                    useHTML: true,
                    format: "<h6 class='chart-text'>{value}</h6>",
                    style: {
                        color: "",
                    },
                },
                categories:
                    getWateringCategories(),

                crosshair: true,
            },
            yAxis: yAxis,
            exporting: false,
            plotOptions: {
                column: {
                    pointPadding: 0,
                    borderWidth: 0,
                    colors: ["#84DFFF"],
                },
            },
            legend: {
                enabled: false,
            },
            series: series,
        };
    };

    return (
        <SzChart options={getChartOptions()}/>);
};

export default ProgrammingChart;
