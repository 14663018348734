import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzDropDown, SzHeader, SzIcon, SzTypographie } from "@suezenv/react-theme-components";
import { bindActionCreators } from "redux";
import { UserActions } from "../../store/actions";
import { menuItems } from "../Container";
import { config } from "../../../config";
import "./header.scss";

interface IHeadersProps {
  handleOnClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  connected: boolean;
  email: string;
  logout: any;
  menuIsOpen: boolean;
  currentTab?: keyof typeof menuItems;
  ssoToken: string;
}

function Header(props: IHeadersProps) {
  const { handleOnClick, connected, logout, menuIsOpen, currentTab, ssoToken } = props;
  const { t } = useTranslation();
  const menuOpt = [
    {
      content: "DÉCONNEXION",
      handleClick: () => logout(false, ssoToken),
      icon: "logout-2",
    },
  ];

  const logoLink = (
    <a className="alm-ev-header__brand px-3" href="#!">
      <img src={config.logo} alt="Logo Suez" width={150} />
    </a>
  );

  const headerContent = () => {
    if (currentTab) {
      switch (currentTab) {
        case menuItems.home:
        default:
          return titleHeader();
      }
    }
    return "";
  };

  const userMenu = (
    <SzDropDown alignRight>
      {menuOpt.map(({ handleClick, icon, content }, index) => {
        return (
          <SzDropDown.Item key={index} onClick={handleClick}>
            <div className="cursor-pointer">
              {icon && <SzIcon icon={icon} variant="line" className="mr-2 align-self-center text-right" />}
              {content}
            </div>
          </SzDropDown.Item>
        );
      })}
    </SzDropDown>
  );

  const titleHeader = () => {
    return (
      <div className="border-left d-none d-xl-block pl-3 pr-3">
        <SzTypographie variant="h2" weight="bold" className="mb-0 mt-2">
          {t("header.title")}
        </SzTypographie>
        <SzTypographie variant="caption">{t("header.subTitle")}</SzTypographie>
      </div>
    );
  };

  if (!connected) {
    return (
      <>
        <SzHeader className="alm-ev-header d-flex align-items-stretch">
          {logoLink}
          <h1 className="alm-ev-header__brand-title px-3">ALM Espace Vert</h1>
        </SzHeader>
      </>
    );
  } else {
    return (
      <>
        <SzHeader className="d-flex">
          <div
            className={`alm-ev-header__menu-icon text-secondary border-right d-none d-xl-block
              ${menuIsOpen ? "menuIsOpen" : "menuIsClose"}`}
            onClick={handleOnClick}
          >
            <SzIcon variant="bold" icon="navigation-menu" className="ml-3" />
            <div className={"alm-ev-header__menu-icon__text"}>
              <p>{t("menu")}</p>
            </div>
          </div>
          {logoLink}
          {headerContent()}
          <div
            className="gaia-header signin d-flex align-items-center ml-auto
                  justify-content-around text-uppercase font-weight-bold  border-left"
          >
            {userMenu}
          </div>
        </SzHeader>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    connected: state.user.logged,
    email: state.user.email,
    ssoToken: state.user.ssoToken,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(UserActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
